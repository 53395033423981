import { ComponentProps, FC, forwardRef, ReactNode } from 'react'

import { Button as ButtonRadix } from '@radix-ui/themes'
import cx from 'clsx'
import { capitalize } from 'lodash'
import { Loader, LoaderColors } from 'ui/Loader'

import classes from './Button.module.scss'

interface ButtonProps extends ComponentProps<typeof ButtonRadix> {
  isLoading?: boolean
  startIcon?: ReactNode
  endIcon?: ReactNode
  buttonSize?: 'md' | 'lg'
  classNameIconBox?: string
}

export const Button: FC<ButtonProps> = forwardRef(
  (
    {
      isLoading,
      children,
      startIcon,
      endIcon,
      size = '2',
      className,
      buttonSize = 'md',
      disabled,
      classNameIconBox,
      ...props
    },
    ref,
  ) => (
    <ButtonRadix
      className={cx(
        classes.button,
        classes[`size${size}`],
        classes[`buttonSize${capitalize(buttonSize)}`],
        { [classes.loading]: isLoading },
        { [classes.disabledButton]: disabled },
        className,
      )}
      disabled={disabled}
      size={size}
      {...props}
      ref={ref}
    >
      {isLoading && (
        <div className={classes.loader}>
          <Loader color={LoaderColors.White} />
        </div>
      )}

      <div className={cx(classes.flex, classNameIconBox)}>
        {startIcon && <span className={classes.icon}>{startIcon}</span>}
        {children}
        {endIcon && <span className={classes.icon}>{endIcon}</span>}
      </div>
    </ButtonRadix>
  ),
)
