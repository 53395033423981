import { devtools } from 'config/devtools'
import { create } from 'zustand'
import { persist } from 'zustand/middleware'

type KeysMenuList = 'cart' | 'storeHouse' | 'settings'
type OpenedList = Record<KeysMenuList, boolean>

export interface Store {
  openedList: OpenedList
  toggle: (key: KeysMenuList) => void
}

export const useMenuList = create<Store>()(
  devtools(
    persist(
      (set) => ({
        openedList: {
          cart: false,
          storeHouse: false,
          settings: false,
        },
        toggle: (key: KeysMenuList) =>
          set(({ openedList }) => ({
            openedList: { ...openedList, [key]: !openedList[key] },
          })),
      }),
      {
        name: 'sidebar.menuList',
      },
    ),
    {
      store: 'sidebar.menuList',
    },
  ),
)
