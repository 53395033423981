import { FC, useEffect, useState } from 'react'

import Cart from 'assets/images/cart.svg?react'
import Storehouse from 'assets/images/storehouse.svg?react'
import { MenuItemListPro } from 'components/template/Sidebar/MenuItemListPro'
import { useMenuList } from 'components/template/Sidebar/store'
import { useTranslate } from 'config/i18n'
import { parametersCompanyAndSetting } from 'constants/parametersCompanyAndSetting'
import { parametersProductsAndServices } from 'constants/parametersProductsAndServices'
import { parametersStorehouse } from 'constants/parametersStorehouse'
import { CompanySuggestion } from 'pro/companies/api/interfaces'
import { CardCompany } from 'pro/companies/components/CardCompany'
import { useGetOneCompanyInfo } from 'pro/companies/queries'
import { useCompanyId } from 'pro/companies/store'

import classes from './MenuMarketPro.module.scss'

export const MenuMarketPro: FC = () => {
  const { companyId } = useCompanyId()
  const { oneCompanyInfo, isLoading } = useGetOneCompanyInfo(companyId)
  const [company, setCompany] = useState<CompanySuggestion>()
  const translate = useTranslate()
  const cart = useMenuList((state) => state.openedList.cart)
  const storeHouse = useMenuList((state) => state.openedList.storeHouse)
  const settings = useMenuList((state) => state.openedList.settings)
  const toggle = useMenuList((state) => state.toggle)

  useEffect(() => {
    setCompany(oneCompanyInfo)
  }, [oneCompanyInfo])

  return (
    <ul className={classes.menu}>
      <CardCompany company={company} isLoading={isLoading} />
      <MenuItemListPro
        icon={<Cart />}
        items={parametersProductsAndServices()}
        openItems={cart}
        setOpenItems={() => toggle('cart')}
        title={translate('pro.products.productsAndServices')}
      />
      <MenuItemListPro
        icon={<Storehouse />}
        items={parametersStorehouse()}
        openItems={storeHouse}
        setOpenItems={() => toggle('storeHouse')}
        title={translate('pro.receipts.storehouse')}
      />
      <MenuItemListPro
        items={parametersCompanyAndSetting()}
        openItems={settings}
        setOpenItems={() => toggle('settings')}
        title={translate('pro.companySetting.companyAndSettings')}
      />
    </ul>
  )
}
