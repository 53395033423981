import {
  NoParamEndpointConstructor,
  OneParamEndpointConstructor,
  TwoParamEndpointConstructor,
} from 'interfaces/api.interfaces'

interface Endpoints {
  getUsers: NoParamEndpointConstructor
  activateUser: NoParamEndpointConstructor
  deactivateUser: NoParamEndpointConstructor
  getUser: OneParamEndpointConstructor
  editUser: OneParamEndpointConstructor
  getCurrentUser: NoParamEndpointConstructor
  createUser: NoParamEndpointConstructor
  resendInviteUser: TwoParamEndpointConstructor
  revokeInviteUser: OneParamEndpointConstructor
  verifyPhone: OneParamEndpointConstructor
  inviteExistUser: OneParamEndpointConstructor
}

export const proUserEndpoints: Endpoints = {
  getUsers: () => '/app/user',
  activateUser: () => 'app/user/activate',
  deactivateUser: () => 'app/user/block',
  getUser: (id) => `app/user/${id}`,
  editUser: (id) => `app/user/${id}`,
  getCurrentUser: () => '/app/user/me',
  createUser: () => 'app/user',
  resendInviteUser: (id, company) => `auth/user/${id}/${company}/invite/resend`,
  revokeInviteUser: (id) => `app/user/recall-invite/${id}`,
  verifyPhone: (phone) => `auth/user/by-phone/${phone}`,
  inviteExistUser: (id) => `app/user/invite/${id}`,
}
