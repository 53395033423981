import { FC, ReactNode, useState } from 'react'

import CaretIcon from 'assets/images/caret-icon.svg?react'
import Gear from 'assets/images/gear.svg?react'
import cx from 'clsx'
import { MenuItem } from 'components/template/Sidebar/MenuItem/MenuItem'
import { MenuItemNavigate } from 'components/template/Sidebar/MenuItemNavigate'
import { Animation, AnimationTypes } from 'ui/Animation'

import classes from './MenuItemListPro.module.scss'

interface NavigateTo {
  to: string
}

interface Item {
  id: number
  name: string
  navigate: NavigateTo
}
interface Props {
  items: Item[]
  title: string
  icon?: ReactNode
  openItems?: boolean
  setOpenItems?: () => void
}

export const MenuItemListPro: FC<Props> = ({ title, items, icon, openItems, setOpenItems }) => {
  const [selectedItemId, setSelectedItemId] = useState<number | null>(null)
  const [selectedParameter, setSelectedParameter] = useState<number | null>(null)

  const onParameterClick = (item: number | null) => {
    setSelectedParameter(item)
  }

  const onClickNavigate = (parametersId: string) => {
    setSelectedItemId(Number(parametersId))
  }

  return (
    <div className={classes.menuItemListPro}>
      <div onClick={setOpenItems}>
        <MenuItem
          caretIcon={<CaretIcon className={cx(classes.caretIcon, { [classes.rotateCaretIcon]: !openItems })} />}
          icon={icon ?? <Gear />}
          onClick={() => onParameterClick(null)}
        >
          {title}
        </MenuItem>
      </div>
      <Animation type={AnimationTypes.ScaleInMini}>
        {openItems && (
          <ul className={classes.list}>
            {items?.map((item) => (
              <div key={item.id} onClick={() => onParameterClick(Number(item.id))}>
                <MenuItemNavigate
                  href={`${item.navigate.to}`}
                  isActive={selectedParameter === item.id}
                  isSelected={selectedItemId === item.id}
                  itemId={item.id}
                  onClickNavigate={() => onClickNavigate(String(item.id))}
                >
                  {item.name}
                </MenuItemNavigate>
              </div>
            ))}
          </ul>
        )}
      </Animation>
    </div>
  )
}
